body {
    margin: 0;
    background: #eeeeee;
}

img {
    max-width: 100%;
    height: auto;
}

@font-face {
    font-family: 'Gilmer Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Regular'), url('./fonts/gilmer-regular.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Bold'), url('./fonts/gilmer-bold.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Heavy'), url('./fonts/gilmer-heavy.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Light'), url('./fonts/gilmer-light.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Outline'), url('./fonts/gilmer-outline.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Medium'), url('./fonts/gilmer-medium.woff') format('woff');
}

html,
body,
#root,
.content-flex {
    height: 100%;
    overflow-x: hidden;
}

.content-flex {
    display: flex;
    overflow: hidden;
}

.content-width {
    flex: 1;
    overflow-y: scroll;
}

a {
    color: #3083ec;
    text-decoration: none;
}

label {
    display: flex;
    align-items: center;
}

/* date pickers */

.MuiPickersCalendarHeader-dayLabel,
.DayPicker-Weekday {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold !important;
}

.MuiPickersDay-day {
    background-color: #4a90e2 !important;
    color: white !important;
}

.MuiPickersDay-dayDisabled {
    background-color: transparent !important;
    color: black !important;
}

.MuiPickersDay-daySelected {
    background-color: #e24a4a !important;
}

.MuiStepLabel-active {
    color: #3083ec !important;
}

.MuiAutocomplete-popper {
    min-width: 300px !important;
}

/* placeholders */

input::placeholder {
    opacity: 0.9 !important;
    color: #333 !important;
}

textarea::placeholder {
    opacity: 1 !important;
    color: #333 !important;
    font-size: 12px !important;
    font-family: 'Gilmer Regular' !important;
}

em {
    color: #333 !important;
}

/* Date picker css override */
@media only screen and (min-width: 576px) {
    .MuiPickersStaticWrapper-staticWrapperRoot {
        width: 100%;
    }

    .MuiPickersBasePicker-pickerView {
        width: 85% !important;
        max-width: 85% !important;
        min-width: 85% !important;
        margin-left: 25px !important;
        height: 410px;
        justify-content: flex-start !important;
    }

    .MuiPickersCalendarHeader-switchHeader .MuiSvgIcon-root {
        width: 2rem !important;
        height: 2rem !important;
        align-items: start;
    }

    .MuiPickersCalendarHeader-iconButton {
        padding: 0 12px 12px !important;
    }

    .MuiTypography-root .MuiTypography-body2 .MuiTypography-colorInherit {
        font-size: 1.2rem !important;
    }

    .MuiPickersCalendar-week {
        height: 50px !important;
        align-items: center !important;
        display: flex !important;
        justify-content: space-around !important;
    }

    .MuiButtonBase-root .MuiIconButton-root .MuiPickersDay-day .MuiPickersDay-hidden .MuiPickersDay-dayDisabled {
        width: 50px !important;
        height: 50px !important;
    }

    .MuiTypography-caption {
        font-size: 1rem !important;
    }

    .MuiPickersCalendarHeader-daysHeader {
        margin: 1rem !important;
        justify-content: space-between !important;
    }

    .MuiPickersCalendarHeader-transitionContainer {
        align-self: self-start !important;
        margin-top: 1px;
    }

    .MuiTypography-body1 {
        font-size: 1rem !important;
    }

    .MuiBox-root-537 {
        margin-top: 15px;
    }
}

/* Always show scrollbar in mobile */
.overflowVisible {
    overflow: auto !important;
}

.overflowVisible::-webkit-scrollbar {
    width: 3px !important;
}
.overflowVisible::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(179, 179, 179) !important;
    border-radius: 10px !important;
}
.overflowVisible::-webkit-scrollbar-thumb {
    background: #2b77e4 !important;
    border-radius: 4px !important;
}

.MuiPickersCalendar-week div {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}
#addCardModal::-webkit-scrollbar {
    display: none;
}
#addCardModal {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.rrt-title {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}
.rrt-text {
    font-size: 14px;
}
/* Consent */
.consent {
    padding: 0 11px; /*? Sums with parent's 5px margin, making 16px*/
    font-family: Roboto, Arial, 'sans-serif';
}
/* Consent titles */
.consent .MsoSubtitle * {
    font-size: 20px !important;
}
.consent h1 span {
    font-size: 28px !important;
}
.consent h2 span {
    font-size: 24px !important;
}
.consent h3 span {
    font-size: 22px !important;
}

/* Consent text */
.consent p:not(.MsoSubtitle) *,
.consent li *,
.consent span {
    font-size: 16px !important;
}
.consent a {
    color: unset;
}

/* Consent list */
.consent ul {
    padding-left: 32px;
}
.consent table {
    margin-left: auto !important;
    margin: auto;
    border: 1px solid;
    border-collapse: collapse;
}
.consent td {
    border: 1px solid #bababa;
}
